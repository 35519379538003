import { OrderVerifications } from '@/translations';
import Report from '@/util/Report';

export default {
	data() {
		return {
			alert: new this.$Alert(),
			approveOrderVerificationUtil: new Report(),
			rejectOrderVerificationUtil: new Report(),
			updateOrderVerificationUtil: new Report(),
		};
	},
	created() {
		this.$options.messages.push(OrderVerifications);
	},
	methods: {
		approveOrderVerification(orderId, callbacks = {}, shippingVerification = false) {
			const trans = {
				title: this.translate('approve_order_alert_title'),
				password: this.translate('password'),
			};
			const options = {
				buttonText: this.translate('ok'),
				cancelButtonText: this.translate('cancel'),
				emptyPasswordMessage: this.translate('password_is_required'),
				config: {
					showCancelButton: true,
					icon: 'warning',
					allowOutsideClick: false,
					allowEscapeKey: false,
				},
				message: this.translate('approve_order_alert_message'),
			};

			this.alert.confirmationWithPassword(trans.title, trans.password, options).then((inputs) => {
				let messageError = this.translate('default_error_message');
				let message = '';
				let success = true;

				const payload = { password: inputs.value };

				const loading = new this.$Alert();
				loading.loading(this.translate('loading'), this.translate('loading_text'), { allowOutsideClick: false, allowEscapeKey: false });
				const type = shippingVerification ? 'shipping' : 'billing';
				this.approveOrderVerificationUtil.approveOrderVerification(orderId, payload, type).then((response) => {
					message = response.response.message;
					if (callbacks.onSuccess) {
						Promise.resolve(callbacks.onSuccess());
					}
				}).catch((errors) => {
					success = false;
					if (typeof errors.errors !== 'undefined') {
						messageError = this.translate('incorrect_password');
					}
					if (typeof errors.messageError !== 'undefined') {
						messageError = errors.messageError;
					}
					delete errors.errors;
				}).finally(() => {
					if (callbacks.onFinally) {
						Promise.resolve(callbacks.onFinally()).finally(() => {
							loading.close();
							if (success) {
								this.alert.toast('success', message, { timer: 6000 });
							} else {
								this.alert.toast('error', messageError, { timer: 6000 });
							}
						});
					} else if (success) {
						this.alert.toast('success', message, { timer: 6000 });
					} else {
						this.alert.toast('error', messageError, { timer: 6000 });
					}
				});
			}).catch(() => {
				if (callbacks.onCancel) {
					Promise.resolve(callbacks.onCancel());
				}
			});
		},
		rejectOrderVerification(orderId, callbacks = {}, shippingVerification = false) {
			const trans = {
				title: this.translate('reject_order_alert_title'),
				password: this.translate('password'),
				notesTitle: this.translate('notes'),
			};
			const options = {
				buttonText: this.translate('ok'),
				cancelButtonText: this.translate('cancel'),
				emptyPasswordMessage: this.translate('password_is_required'),
				config: {
					showCancelButton: true,
					icon: 'warning',
					allowOutsideClick: false,
					allowEscapeKey: false,
				},
			};

			const type = shippingVerification ? 'shipping' : 'billing';
			this.alert.confirmationWithPasswordAndNotes(trans.title, trans.password, trans.notesTitle, options).then((inputs) => {
				let messageError = this.translate('default_error_message');
				let message = '';
				let success = true;

				const { value } = inputs;
				const payload = {
					notes: value.notes,
					password: value.password,
				};
				const loading = new this.$Alert();
				loading.loading(this.translate('loading'), this.translate('loading_text'), { allowOutsideClick: false, allowEscapeKey: false });
				this.rejectOrderVerificationUtil.rejectOrderVerification(orderId, payload, type).then((response) => {
					message = response.response.message;
					if (callbacks.onSuccess) {
						Promise.resolve(callbacks.onSuccess());
					}
				}).catch((errors) => {
					success = false;
					if (typeof errors.errors !== 'undefined') {
						messageError = this.translate('incorrect_password');
					}
					if (typeof errors.messageError !== 'undefined') {
						messageError = errors.messageError;
					}
					delete errors.errors;
				}).finally(() => {
					if (callbacks.onFinally) {
						Promise.resolve(callbacks.onFinally()).finally(() => {
							loading.close();
							if (success) {
								this.alert.toast('success', message, { timer: 6000 });
							} else {
								this.alert.toast('error', messageError, { timer: 6000 });
							}
						});
					} else if (success) {
						this.alert.toast('success', message, { timer: 6000 });
					} else {
						this.alert.toast('error', messageError, { timer: 6000 });
					}
				});
			}).catch(() => {
				if (callbacks.onCancel) {
					Promise.resolve(callbacks.onCancel());
				}
			});
		},
		requestDocumentUpdate(orderId, callbacks = {}) {
			const trans = {
				title: this.translate('ask_update_order_alert_title'),
				password: this.translate('password'),
				notesTitle: this.translate('notes'),
			};
			const options = {
				buttonText: this.translate('ok'),
				cancelButtonText: this.translate('cancel'),
				emptyPasswordMessage: this.translate('password_is_required'),
				config: {
					showCancelButton: true,
					icon: 'warning',
					allowOutsideClick: false,
					allowEscapeKey: false,
				},
			};

			this.alert.confirmationWithPasswordAndNotes(trans.title, trans.password, trans.notesTitle, options).then((inputs) => {
				let messageError = this.translate('default_error_message');
				let message = '';
				let success = true;

				const { value } = inputs;
				const payload = {
					notes: value.notes,
					password: value.password,
				};
				const loading = new this.$Alert();
				loading.loading(this.translate('loading'), this.translate('loading_text'), { allowOutsideClick: false, allowEscapeKey: false });
				this.updateOrderVerificationUtil.requestDocumentUpdate(orderId, payload).then((response) => {
					message = response.response.message;
					if (callbacks.onSuccess) {
						Promise.resolve(callbacks.onSuccess());
					}
				}).catch((errors) => {
					success = false;
					if (typeof errors.errors !== 'undefined') {
						messageError = this.translate('incorrect_password');
					}
					if (typeof errors.messageError !== 'undefined') {
						messageError = errors.messageError;
					}
					delete errors.errors;
				}).finally(() => {
					if (callbacks.onFinally) {
						Promise.resolve(callbacks.onFinally()).finally(() => {
							loading.close();
							if (success) {
								this.alert.toast('success', message, { timer: 6000 });
							} else {
								this.alert.toast('error', messageError, { timer: 6000 });
							}
						});
					} else if (success) {
						this.alert.toast('success', message, { timer: 6000 });
					} else {
						this.alert.toast('error', messageError, { timer: 6000 });
					}
				});
			}).catch(() => {
				if (callbacks.onCancel) {
					Promise.resolve(callbacks.onCancel());
				}
			});
		},
	},
};
